// IMPORT REACT HOOK
    import React, { useState } from 'react';
    import './login.css';

// IMPORT DEPENDENCIES
    import { Link, Redirect } from 'react-router-dom';
    import { BsArrowReturnLeft, BsX } from 'react-icons/bs';
    import { Snackbar } from '@material-ui/core';
    import { FaThinkPeaks } from 'react-icons/fa';


const Login = () => {
    let USER = '123456';
    let PASSWORD = '123456';    

    // set hooks
        const [openUserError, setOpenUserError] = useState(false);
        const [redirect, setRedirect] = useState(false);
    // set toggle modal
        const toggleModal = () => {
            setOpenUserError(!openUserError)
        }
    // set get values inputs
        const [change, setChange] = useState({
            user: '',
            password: ''
        })
        const handlechange = e => {
            const { name, value } = e.target;
            setChange(prevState => ({
                ...prevState,
                [name] : value
            }))
        }


    // FUNCTIONS
    const login = e => {
        e.preventDefault();
        if(change.user === USER && change.password === PASSWORD) {
            setRedirect(true);
        } else {
            toggleModal();
        }

    }

    if(redirect){
        return <Redirect to={'/home/dashboard'} />
    }

    return (
        <div className={'login'}>
            <Link to={'/'} className={'btn shadow-none'} id={'prev-home'}><BsArrowReturnLeft/></Link>
            <div className={'container d-flex justify-content-center'}>
                <form onSubmit={(e) => login(e)} className={'box-login'}>
                    <div className={'d-flex justify-content-center'}>
                        <FaThinkPeaks className={'iconlogo'} />
                    </div>
                    <p className={'title-login'}>Faça seu login e tenha acesso ao dashboard!</p>
                    <p className={'title-login-subtitle'}>Coloque seus dados logo abaixo no formulário</p>

                    <div className={'box-input'}>
                        <div className={'title-input'}>Usuário*</div>
                        <input name={'user'} onChange={handlechange} required className={'form-control shadow-none bg-transparent'} id={'input-login'} placeholder={'Seu usuário'} />
                    </div>
                    <div className={'box-input'}>
                        <div className={'title-input'}>Senha*</div>
                        <input name={'password'} onChange={handlechange} required type={'password'} className={'form-control shadow-none bg-transparent'} id={'input-login'} placeholder={'******'} />
                    </div>
                    <div className={'d-flex justify-content-center'}>
                        <button type={'submit'} className={'btn btn-outline-dark shadow-none'} id={'btn-login'}>login</button>
                    </div>
                    
                    {/* MODAL */}
                        <Snackbar open={openUserError} 
                            onClose={toggleModal}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            className={'snackbar'}
                            message="Usuário ou senha incorreto"
                            autoHideDuration={3000}
                            action={
                                <button className={'btn shadow-none'}><BsX color={'white'}/></button>
                            }
                        />
                </form>
            </div>


            
        </div>
    )
}

export default Login;