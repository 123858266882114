import firebase from 'firebase';

var firebaseConfig = {
    apiKey: "AIzaSyAYrBLdda8MqXTvKN6MYpyF8ZGVIhfqQPk",
    authDomain: "bind-2d2e9.firebaseapp.com",
    projectId: "bind-2d2e9",
    storageBucket: "bind-2d2e9.appspot.com",
    messagingSenderId: "41782200213",
    appId: "1:41782200213:web:71ff8866b4bd896989989c"
  };

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

let db = firebase.firestore();

export { db, firebase };