import React, { useState, useEffect } from 'react';
import './list.css';

// set dependencies
    import { db } from '../../../firebase'; 
    import { FaDatabase } from 'react-icons/fa';
    import { FaTelegramPlane, FaSearch } from 'react-icons/fa';
    import { Drawer } from '@material-ui/core';
    import DrawerCrud from '../../../components/drawerCRUD';
    import empty from '../../../assets/empty.png';

const List = () => {

// set hooks
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [content, setContent] = useState([]);
    const [search, setSearch] = useState('');

// set open drawer
    const toggleOpen = () => {
        setOpen(!open);
    }
// set user
    const setUser = client => {
        toggleOpen();
        setContent(client)
    }


// get data
    const fetchData = async () => {
        let list = [];
        const getClient = db.collection('clientes');
        let getAllClient = await getClient.get();
        for(const doc of getAllClient.docs) {
            list.push({ id: doc.id, ...doc.data() })
            setLoading(true)
        }
        setData(list);
    }

    useEffect(() => {
        fetchData();
        return () => { 
            
        }
    }, [data]);

// filter data
    const filteredOptions = data.filter(
      option =>
        option.nome.toLowerCase().includes(search.toLowerCase()) ||
        !search
    );

    return(
        <div className={'list'}>
            <div className={'header-list'}>
                <div className={'container'}>
                    <div className={'d-flex justify-content-between wrap'}>
                        <div className={'box-data-info-length'}>
                            <div className={'d-flex justify-content-start'}>
                                <FaDatabase className={'icon-db'} /> 
                                <p className={'list-title'}>Clientes</p>
                            </div>
                            <p className={'bar_'}></p>

                            <p className={'qtd-list'}>Quantidade</p>
                            <p className={'qtd-value'}>{data.length}</p>
                        </div>
                        <div className={'div-input-search d-flex justify-content-center'}>
                            <input onChange={ e => setSearch(e.target.value) } className={'form-control shadow-none'} id={'input-search'} />
                            <FaSearch className={'input-icon'} />
                        </div>
                    </div>
                </div>
            </div>
            <div className={'header-bottom'}>
                <div className={'container'}>
                    <div className={'div-list-client d-flex justify-content-start'}>
                        {
                            loading
                            ?
                            <>
                                {
                                filteredOptions.length > 0?
                                    (
                                        filteredOptions.map((client, index) => (
                                        <div className={'div-box-clientes'}>
                                                <div key={index} className={'box-clientes'}> 
                                                    <div className={'desc-box-clientes'} key={index}>
                                                        <p className={'p-cliente-nome'}> {client.nome} </p>
                                                        <p className={'p-cliente-cep'}> {client.cep} </p>
                                                        <p className={'p-cliente-desc'}> {client.descricao} </p>
                                                        <p className={'bar-client'}></p>
                                                        <div className={'d-flex justify-content-between'}>
                                                            <img className={'perfil-img-client'} src={client.image} alt={'perfil'} />
                                                            <div>
                                                                <p className={'p-cliente-data'}> {client.data} </p>
                                                                <p className={'p-cliente-hora'}> {client.hora} </p>
                                                            </div>
                                                        </div>
                                                        <div className={'d-flex justify-content-end shadow-none'}>
                                                            <button onClick={ () => setUser(client) } className={'btn shadow-none'} id={'plus'}> <FaTelegramPlane/> visualizar</button>
                                                        </div>
                                                        <Drawer open={open} onClose={toggleOpen} anchor={'right'}>
                                                            <DrawerCrud content={content} toggleOpen={toggleOpen} />
                                                        </Drawer>
                                                    </div>
                                                </div>   
                                        </div>
                                    ))
                                    )
                                    :
                                    <div className={'center'}>
                                        <div>
                                            <img alt={'empty'} className={'empty'} src={empty} />
                                            <p className={'empty-reg'}>Nenhum registro encontrado: {search}</p>
                                        </div>
                                    </div>
                                }
                            </>
                            
                            :
                            <div className={'box-loading'}>
                                <img alt={'loading'} id={'loading'} src={'https://raw.githubusercontent.com/Codelessly/FlutterLoadingGIFs/master/packages/circular_progress_indicator.gif'} />
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default List;
