import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom'; 

// PAGES
    // HOME
        import Bind from './pages/home/bind';
        import Login from './pages/home/login';
    // PRIVATE
        import Dashboard from './pages/private/dashboard';
        import List from './pages/private/list';
        import ListUser from './pages/private/listUser';
        import Register from './pages/private/register';
        import RegisterUser from './pages/private/registerUser';

// COMPONENTS
    import Menu from './components/menu';
    import Success from './components/success';

const Routes = () => {
    return(
        <BrowserRouter>
            <Switch>
                <Route exact path={'/'} component={Bind} />
                <Route exact path={'/login'} component={Login} />

                <Route path={'/home'}
                    render={({ match: {path} }) => {
                        return(
                            <div className={'d-flex justify-content-start'}>
                                <Menu/>
                                <Switch>
                                    <Route exact path={`${path}/dashboard`} component={Dashboard} />
                                    <Route exact path={`${path}/lista`} component={List} />
                                    <Route exact path={`${path}/lista-usuarios`} component={ListUser} />
                                    <Route exact path={`${path}/registrar-cliente`} component={Register} />
                                    <Route exact path={`${path}/registrar-usuario`} component={RegisterUser} />
                                    <Route exact path={`${path}/cadastro-do-cliente-concluido`} component={Success} />
                                    <Route exact path={`${path}/*`} component={() => { return(<p>ERROR 404</p>) }} />
                                </Switch>
                            </div>
                        )
                    }}
                />
                <Route exact path={'*'} component={() => { return(<p>ERROR 404</p>) }} />
                
            </Switch>
        </BrowserRouter>
    )
}

export { Routes };
