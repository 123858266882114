import React from 'react';
import './dashboard.css';

// set dependencies
    import { BsArrowRight } from 'react-icons/bs';
    import { BsArrow90DegDown } from 'react-icons/bs';
    import { Link } from 'react-router-dom';  

// set images
    import Dash from '../../../assets/dash.png';

const Dashboard = () => {

    return (
        <div className={'dashboard '}>
            <div className={'container'}>
                <div className={'info-welcome d-flex justify-content-start wrap'}>
                    <p className={'dashboard-title'}>Seja bem vindo ao </p> <p className={'bi'}>Bind!</p>
                </div>
                <p className={'date'}>{ new Date().toLocaleString('pt', {day: 'numeric', month: 'long', year: 'numeric'}) }</p>

                <div className={'d-flex justify-content-between wrap'}>
                    <div className={''}>
                        <p className={'whatsdo'}><BsArrow90DegDown/> O que você deseja fazer?</p>
                        <div>
                            <Link to={'/home/lista'} id={'btn-option-dashboard'} className={'btn btn-light shadow-none'}>
                                ver todos os clientes cadastrado <BsArrowRight/>
                            </Link><br/>
                            <Link to={'/home/lista-usuarios'} id={'btn-option-dashboard'} className={'btn btn-light shadow-none'}>
                                ver todos os usuários cadastrado <BsArrowRight/>
                            </Link><br/>
                            <Link to={'/home/registrar-cliente'} id={'btn-option-dashboard'} className={'btn btn-light shadow-none'}>
                                Cadastrar novos clientes <BsArrowRight/>
                            </Link><br/>
                            <Link to={'/home/registrar-usuario'} id={'btn-option-dashboard'} className={'btn btn-light shadow-none'}>
                                Cadastrar usuário para o acesso pelo app Bind <BsArrowRight/>
                            </Link>
                        </div>
                    </div>
                    <div>
                        <img src={Dash} className={'img-dash'} alt={'dash'} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Dashboard;