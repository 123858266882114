import React, { useState, useRef } from 'react';
import './edit.css';

// set dependecies
    import { db, firebase } from '../../firebase';
    import { FaUpload } from 'react-icons/fa';

const Edit = ({ toggleEdit, toggleOpen, client }) => {



    // set USEREF
        const fileInput = useRef(null);

    // set usestates hooks
        const [file, setFile] = useState(null);

    // const [url, setUrl] = useState(null);
        const [image, setImage] = useState('');
        const [showImage, setShowImage] = useState(null);
        const [loading, setLoading] = useState(false);

    // set change inputs
        const [change, setChange] = useState(client)

    // get inputs values
        const handlechange = e => {
            const { name, value } = e.target;
            setChange(prevState => ({
                ...prevState,
                [name] : value
            }))
        }

    // set handle image
        const handleImage = e => {
            setFile(e.target.files[0]);
            setImage(e.target.files[0].name)
            setShowImage(URL.createObjectURL(e.target.files[0]))
        }

    // event to save
        const saveEdit = () => {
            setLoading(true);
            if(file == null) {
                const saveEdit = db.collection('clientes').doc(change.id).update(change);
                saveEdit.then(() => {
                    setLoading(false);
                    toggleOpen();
                    toggleEdit();
            })
            }
            else 
            {
                const editImage = firebase.storage().ref(`imagesClient/${file.name}`).put(file);
                editImage.on("state_changed", console.log, console.erro, () => {
                firebase.storage()
                    .ref('imagesClient')
                        .child(file.name)
                            .getDownloadURL()
                                .then(url => {
                                    let data = {
                                        nome: change.nome,
                                        sobrenome: change.sobrenome,
                                        image: url,
                                        nomecompleto: change.nomecompleto,
                                        descricao: change.descricao,
                                        estado: change.estado,
                                        cidade: change.cidade,
                                        cep: change.cep,
                                        rua: change.rua,
                                        numero: change.numero,
                                        complemento: change.complemento,
                                        data: new Date().toLocaleString('pt', {day: 'numeric', month: 'long', year: 'numeric'}),
                                        hora: `${new Date().getHours()}:${new Date().getMinutes()}`
                                    }
                                    const saveDatauser = db.collection('clientes').doc(change.id);
                                    saveDatauser.update(data);
                                    setLoading(false);
                                    toggleOpen();
                                    toggleEdit();
                                })
                })
            }
            
            
        }

    return (
        <div className={'edit'}>
           <div className={'box-form-edit'}>
                <div>
                    <p className={'reg-client-edit'}>Por favor, preencha todos os campos para cadastrar o cliente</p>
                    <p className={'data-user-edit'}>Dados do usuário</p>
                </div>
                <div className={'d-flex justify-content-between box-data-user-edit wrap'}>
                    <div className={'box-input-data'}>
                        <div className={'box-input-data-user'}>
                            <p className={'name-user'}>Nome*</p>
                            <input onChange={handlechange} value={change && change.nome} required name={'nome'} id={'input-data-user-edit1'} className={'form-control shadow-none'} placeholder={'Ex: Leandro'} />
                        </div>
                        <div className={'box-input-data-user'}>
                            <p className={'name-user'}>sobrenome*</p>
                            <input onChange={handlechange} value={ change && change.sobrenome } required name={'sobrenome'} id={'input-data-user-edit2'} className={'form-control shadow-none'} placeholder={'Ex: Martins'}  />
                        </div>
                    </div>
                    <div className={'d-flex justify-content-start box-img-perfil wrap'}>
                        <div>
                            <img className={'img-perfil-user'} src={showImage ? showImage : change.image } alt={showImage} /><br/>
                        </div>
                        <div className={''}>
                            <input type={'file'} onChange={handleImage} ref={fileInput} style={{display: 'none'}} />
                            <small className={'img-name'}> {image} </small><br/>
                            <button onClick={() => fileInput.current.click()} type={'button'} className={'btn btn-outline-dark shadow-none'} id={'btn-upload'}><FaUpload/> Carregar foto do usuário</button>
                            <div className={'box-input-data-user'}>
                                <p className={'name-user'}>nome completo*</p>
                                <input onChange={handlechange} value={ change && change.nomecompleto } required name={'nomecompleto'} id={'input-data-user-edit3'} className={'form-control shadow-none'} placeholder={'Ex: Leandro matis da silva'}  />
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <p className={'name-user-edit'}>Descrição do cliente*</p>
                    <textarea onChange={handlechange} value={ change && change.descricao } required name={'descricao'} id={'descricao-edit'} className={'form-control shadow-none'} placeholder={'Ex: Cliente com cabelo longo...'}>
                    </textarea>
                </div>

                <p className={'data-addr'}>Endereço</p>
                <div className={'d-flex justify-content-between wrap'}>
                    <div className={'box-input-data'}>
                        <div className={'box-input-data-user'}>
                            <p className={'name-user'}>Cidade*</p>
                            <input onChange={handlechange} value={ change && change.estado } name={'estado'} id={'input-data-user-edit4'} className={'form-control shadow-none'} placeholder={'Ex: Ceara'} />
                        </div>
                        <div className={'box-input-data-user'}>
                            <p className={'name-user'}>Fortaleza*</p>
                            <input onChange={handlechange} value={ change && change.cidade } name={'cidade'} id={'input-data-user-edit5'} className={'form-control shadow-none'} placeholder={'Ex: Horizonte'}  />
                        </div>
                    </div>
                    <div className={'box-input-data'}>
                        <div className={'box-input-data-user'}>
                            <p className={'name-user'}>Cep*</p>
                            <input onChange={handlechange} value={ change && change.cep } name={'cep'} id={'input-data-user-edit6'} className={'form-control shadow-none'} placeholder={'Ex: 60014546'} />
                        </div>
                        <div className={'box-input-data-user'}>
                            <p className={'name-user'}>Rua*</p>
                            <input onChange={handlechange} value={ change && change.rua } name={'rua'} id={'input-data-user-edit7'} className={'form-control shadow-none'} placeholder={'Ex: Rua da paz'}  />
                        </div>
                    </div>
                    <div className={'box-input-data'}>
                        <div className={'box-input-data-user'}>
                            <p className={'name-user'}>Número*</p>
                            <input onChange={handlechange} value={ change && change.numero } name={'numero'} id={'input-data-user-edit8'} className={'form-control shadow-none'} placeholder={'Ex: 2020'} />
                        </div>
                        <div className={'box-input-data-user'}>
                            <p className={'name-user'}>Complemento*</p>
                            <input onChange={handlechange} value={ change && change.complemento } name={'complemento'} id={'input-data-user-edit9'} className={'form-control shadow-none'} placeholder={'Ex: perto do posto de combustivel'}  />
                        </div>
                    </div>
                </div>
                <div className={'d-flex justify-content-end'} style={{width: '100%'}}>
                    <button className={'btn'} id={'btn-save'} onClick={ () => saveEdit() }>
                        Salvar
                    </button>
                    <button onClick={() => toggleEdit()} className={'btn'} id={'btn-exit'}>
                        Voltar
                    </button>
                    {
                        loading
                        ?
                        <div className={'loading-edit d-flex justify-content-center'}>
                            <img alt={'loading'} className={'loading-gif'} src={'https://raw.githubusercontent.com/Codelessly/FlutterLoadingGIFs/master/packages/circular_progress_indicator.gif'} />
                        </div>
                        :
                        <>
                            
                        </>
                    }
                </div>
            </div>
        </div>
    )
}

export default Edit;
