import React from 'react';
import './success.css';
import { Link } from 'react-router-dom';

const Success = () => {
    return (
        <div className={'success'}>
            <div className={'d-flex justify-content-center wrap-reverse'}>
                <img alt={'gif-success'} className={'img-success'} src={'https://salesloopx.com/images/ce46d9_3f38fd870f0e44df928485e2e1c7686b_mv2.gif'} />
                <div>
                    <p className={'success-text'}>Cadastro feito com sucesso!</p>
                </div>
            </div>
            <div className={'d-flex justify-content-center'}>
                <div className={'wrap'}> 
                    <Link to={'/home/registrar-cliente'} className={'btn btn-dark shadow-none'} id={'btn-info-prev'}>Cadastrar novos clientes</Link>
                    <Link to={'/home/lista'} className={'btn btn-dark shadow-none'} id={'btn-info-prev'}>Visualizar listas dos clientes</Link>
                </div>
            </div>
        </div>
    )
}

export default Success;
