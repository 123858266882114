// IMPORT REACT HOOK
    import React from 'react';
    import './bind.css';

// IMPORT DEPENDENCIES
    import { Link } from 'react-router-dom';
    import { FaThinkPeaks, FaTelegram } from 'react-icons/fa';

// IMPORT IMAGES
    import Buffer from '../../../assets/buffer.png';

const Bind = () => {
    return (
        <div className={'bind'}>
            <div className={'d-flex justify-content-between container wrap-bind'}>
                <div>
                    <p className={'welcome-title'}>Seja bem vindo ao</p>
                    <p className={'welcome-subtitle'}>Bind <FaThinkPeaks /> </p>
                    <p className={'welcome-content'}>
                        Sistema de cadastro de clientes que tem como objetivo aprimorar 
                        o meu conhecimento na área de desenvolvimento web e mobile utilizando 
                        o framework reactjs com react-native com um designer atraente e simples.
                    </p>
                    <Link to={'/login'}>
                        <button className={'next-login'}>
                            Vamos lá!
                            <FaTelegram className={'ml-2'}/>
                        </button>
                    </Link>
                </div>
                <div>
                    <img alt={'buffer'} className={'buffer-img'} src={Buffer} />
                </div>
            </div>
        </div>
    )
}

export default Bind;
