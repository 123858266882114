import React, { useState } from 'react';

// set dependencies
    import { db } from '../../firebase';
    import { FaEdit } from 'react-icons/fa';
    import { Drawer } from '@material-ui/core';
    import { BsX } from 'react-icons/bs';

// set components
    import EditUser from '../editUser';

const DrawerCrudUser = ({ content, toggleOpen }) => {

// set hook
    const [del, setDel] = useState(false);
    const [edit, setEdit] = useState(false);

// set toggle drawer
    const toggleDel = () => {
        setDel(!del);
    }
    const toggleEdit = () => {
        setEdit(!edit);
    }

// delete client
    const deleteClient = id => {
        toggleOpen()
        db.collection('clientes').doc(id).delete();
    }
    
    return (
        <div className={'content'}>
            <div className={'d-flex justify-content-between'}>
                <p className={'content-nome'}>{content.nome}</p>
                <button className={'btn shadow-none'} onClick={() => toggleOpen()}> <BsX size={27} /> </button>
            </div>
            <p className={'content-cep'}>{content.cep}</p>
            <p className={'content-desc'}>{content.descricao}</p>
            <img src={content.image} className={'content-img'} alt={'perfil'}/>
            <p className={'content-nomecompleto'}>{content.nomecompleto}</p>
            <div className={'box-addr'}>
                <p className={'addr-end'}>informações</p>
                <p className={'content-estado'}>usuario: {content.usuario}</p>
                <p className={'content-cidade'}>senha: {content.senha}</p>
                <p className={'content-cidade'}>nome: {content.nome}</p>
                <p className={'content-cidade'}>sobrenome: {content.sobrenome}</p>
            </div>
            <div className={'box-cad'}>
                <p className={'data-cad'}>Data do cadastro</p>
                <p className={'content-data'}>{content.data}</p>
                <p className={'content-hora'}>{content.hora}</p>
            </div>
            <div className={'d-flex justify-content-between'}>
                <button className={'btn shadow-none'} id={'btn-edit'} onClick={() => toggleEdit()}> Editar <FaEdit/> </button>
                <button className={'btn '} id={'btn-delete'} onClick={() => toggleDel()}> Deletar <BsX/> </button>
            </div>









            {/* DRAWERS */}
                {/* DELETE */}
                    <Drawer open={del} onClose={toggleDel} anchor={'right'}>
                        <div className={'box-delete'}>
                            <p className={'delete-title'}>Tem certeza que deseja apagar?</p>
                            <p className={'delete-subtitle'}>
                                Se você clicar no sim todos os dados dos clientes sera apagado e infelizmente
                                não vai ser possível recuperar os dados do cliente e terá que ser feito outro recadastramento
                            </p>
                            <div className={'d-flex justify-content-center mt-5'}>
                                <button id={'btn1'} className={'btn btn-success'} onClick={() => deleteClient(content.id)}>Eu desejo apagar os dados desse cliente</button>
                            </div>
                            <div className={'d-flex justify-content-center'}>
                                <button id={'btn3'}  className={'btn'} onClick={() => toggleDel()}>Voltar</button>
                            </div>
                            
                        </div>
                    </Drawer>
                {/* EDIT */}
                <Drawer open={edit} onClose={toggleEdit} anchor={'left'}>
                    <EditUser toggleEdit={toggleEdit} toggleOpen={toggleOpen} client={content} />
                </Drawer>
        </div>
    )
}

export default DrawerCrudUser;
