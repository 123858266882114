import React, { useState, useRef } from 'react';
import './register.css';

// set dependecies
    import { firebase } from '../../../firebase';
    import { FaUpload } from 'react-icons/fa';
    import { Redirect } from 'react-router-dom';

const Register = () => {
    // set USEREF
        const fileInput = useRef(null);
    // set usestates hooks
        const [file, setFile] = useState(null);
        // const [url, setUrl] = useState(null);
        const [image, setImage] = useState('');
        const [showImage, setShowImage] = useState(null);
        const [loading, setLoading] = useState(false); 
        const [redirect, setRedirect] = useState(false);
        const [change, setChange] = useState({
            nome: '',
            sobrenome: '',
            image: '',
            nomecompleto: '',
            descricao: '',
            estado: '',
            cidade: '',
            cep: '',
            rua: '',
            numero: '',
            complemento: ''
        })

    // get inputs values
        const handlechange = e => {
            const { name, value } = e.target;
            setChange(prevState => ({
                ...prevState,
                [name] : value
            })) 
        }

    // set handle image
        const handleImage = e => {
            setFile(e.target.files[0]);
            setImage(e.target.files[0].name)
            setShowImage(URL.createObjectURL(e.target.files[0]))
        }
    // set save client
        const save = e => {
            e.preventDefault();
            setLoading(true);
            const getAllData = firebase.storage().ref(`imagesClient/${file.name}`).put(file);
            getAllData.on("state_changed", console.log, console.erro, () => {
                firebase.storage()
                    .ref('imagesClient')
                        .child(file.name)
                            .getDownloadURL()
                                .then(url => {
                                    let data = {
                                        nome: change.nome,
                                        sobrenome: change.sobrenome,
                                        image: url,
                                        nomecompleto: change.nomecompleto,
                                        descricao: change.descricao,
                                        estado: change.estado,
                                        cidade: change.cidade,
                                        cep: change.cep,
                                        rua: change.rua,
                                        numero: change.numero,
                                        complemento: change.complemento,
                                        data: new Date().toLocaleString('pt', {day: 'numeric', month: 'long', year: 'numeric'}),
                                        hora: `${new Date().getHours()}:${new Date().getMinutes()}`
                                    }
                                    const saveDatauser = firebase.firestore().collection('clientes').doc();
                                    saveDatauser.set(data);
                                    setRedirect(true);
                                    setLoading(false);
                                })
            })
        }

    if(redirect) {
        return <Redirect to={'/home/cadastro-do-cliente-concluido'} />
    }

    return (
        <div className={' container d-flex justify-content-center'}>
            {
                loading
                ?
                <div className={'save-loading d-flex justify-content-center'}>
                    <img alt={'loading'} className={'loading-gif'} src={'https://raw.githubusercontent.com/Codelessly/FlutterLoadingGIFs/master/packages/circular_progress_indicator.gif'} />
                </div>
                :
                <form onSubmit={ e => save(e) } className={'box-form'}>
                    <div>
                        <p className={'reg-client'}>Por favor, preencha todos os campos para cadastrar o cliente</p>
                        <p className={'data-user'}>Dados do usuário</p>
                    </div>
                    <div className={'d-flex justify-content-between box-data-user wrap'}>
                        <div className={'box-input-data'}>
                            <div className={'box-input-data-user'}>
                                <p className={'name-user'}>Nome*</p>
                                <input onChange={handlechange} required name={'nome'} id={'input-data-user'} className={'form-control shadow-none'} placeholder={'Ex: Leandro'} />
                            </div>
                            <div className={'box-input-data-user'}>
                                <p className={'name-user'}>sobrenome*</p>
                                <input onChange={handlechange} required name={'sobrenome'} id={'input-data-user'} className={'form-control shadow-none'} placeholder={'Ex: Martins'}  />
                            </div>
                        </div>
                        <div>
                            <div className={'d-flex justify-content-start box-img-perfil wrap'}>
                                <div>
                                    <img className={'img-perfil-user'} src={showImage?showImage:null} alt={showImage} /><br/>
                                </div>
                                <div>
                                    <input type={'file'} onChange={handleImage} ref={fileInput} style={{display: 'none'}} />
                                    <small className={'img-name'}> {image} </small><br/>
                                    <button onClick={() => fileInput.current.click()} type={'button'} className={'btn btn-outline-dark shadow-none'} style={{borderRadius: 60}}><FaUpload/> Upload</button>
                                    <div className={'box-input-data-user'}>
                                        <p className={'name-user'}>nome completo*</p>
                                        <input onChange={handlechange} required name={'nomecompleto'} id={'input-data-user'} className={'form-control shadow-none'} placeholder={'Ex: Leandro matis da silva'}  />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div>
                        <p className={'name-user'}>Descrição do cliente*</p>
                        <textarea onChange={handlechange} required name={'descricao'} className={'form-control shadow-none'} id={'descClient'} placeholder={'Ex: Cliente com cabelo longo...'}>
                        </textarea>
                    </div>

                    <p className={'data-addr'}>Endereço</p>
                    <div className={'d-flex justify-content-between wrap'}>
                        <div className={'box-input-data'}>
                            <div className={'box-input-data-user'}>
                                <p className={'name-user'}>Cidade*</p>
                                <input onChange={handlechange} required name={'estado'} id={'input-data-user'} className={'form-control shadow-none'} placeholder={'Ex: Ceara'} />
                            </div>
                            <div className={'box-input-data-user'}>
                                <p className={'name-user'}>Fortaleza*</p>
                                <input onChange={handlechange} required name={'cidade'} id={'input-data-user'} className={'form-control shadow-none'} placeholder={'Ex: Horizonte'}  />
                            </div>
                        </div>
                        <div className={'box-input-data'}>
                            <div className={'box-input-data-user'}>
                                <p className={'name-user'}>Cep*</p>
                                <input onChange={handlechange} required name={'cep'} id={'input-data-user'} className={'form-control shadow-none'} placeholder={'Ex: 60014546'} />
                            </div>
                            <div className={'box-input-data-user'}>
                                <p className={'name-user'}>Rua*</p>
                                <input onChange={handlechange} required name={'rua'} id={'input-data-user'} className={'form-control shadow-none'} placeholder={'Ex: Rua da paz'}  />
                            </div>
                        </div>
                        <div className={'box-input-data'}>
                            <div className={'box-input-data-user'}>
                                <p className={'name-user'}>Número*</p>
                                <input onChange={handlechange} required name={'numero'} id={'input-data-user'} className={'form-control shadow-none'} placeholder={'Ex: 2020'} />
                            </div>
                            <div className={'box-input-data-user'}>
                                <p className={'name-user'}>Complemento*</p>
                                <input onChange={handlechange} required name={'complemento'} id={'input-data-user'} className={'form-control shadow-none'} placeholder={'Ex: perto do posto de combustivel'}  />
                            </div>
                        </div>
                    </div>
                    <div className={'d-flex justify-content-end'} style={{width: '100%'}}>
                        <button disabled={!file} type={'submit'} className={'btn'} id={'btn-save'}>
                            Salvar
                        </button>
                    </div>
                </form>
            }
        </div>
    )
}

export default Register;