// set react hooks
    import React from 'react';
    import './menu.css';

// set dependencies
    import { NavLink } from 'react-router-dom';  
    import { FaThinkPeaks, FaUsers } from 'react-icons/fa';
    import { BsGraphUp, BsNewspaper, BsPencilSquare, BsPersonPlusFill } from 'react-icons/bs';

const Menu = () => {
    return (
        <div className={'menu'}>
            <div id={'icon-menu-logo'} className={'d-flex justify-content-center flex-column display-none'}>
                <FaThinkPeaks/>
            </div>
            <div className={'list-menu'}>
                <div className={'icon-menu'}>
                    <NavLink to={'/home/dashboard'} activeClassName={"activeLink"} className={'icon-menu-link'}>
                        <BsGraphUp className={'icon-responsive'} />
                    </NavLink>
                </div>
                <div className={'icon-menu'}>
                    <NavLink to={'/home/lista'} activeClassName={"activeLink"} className={'icon-menu-link'}>
                        <BsNewspaper className={'icon-responsive'} />
                    </NavLink>
                </div>
                <div className={'icon-menu'}>
                    <NavLink to={'/home/lista-usuarios'} activeClassName="activeLink" className={'icon-menu-link'}>
                        <FaUsers className={'icon-responsive'} />
                    </NavLink>
                </div>
                <div className={'icon-menu'}>
                    <NavLink to={'/home/registrar-cliente'} activeClassName="activeLink" className={'icon-menu-link'}>
                        <BsPencilSquare className={'icon-responsive'} />
                    </NavLink>
                </div>
                <div className={'icon-menu'}>
                    <NavLink to={'/home/registrar-usuario'} activeClassName="activeLink" className={'icon-menu-link'}>
                        <BsPersonPlusFill className={'icon-responsive'} />
                    </NavLink>
                </div>
            </div>
        </div>
    )
}

export default Menu;
